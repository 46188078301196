/* You can add global styles to this file, and also import other style files */

@import "assets/app_styles.scss";
@import "assets/plugins/web-fonts/icons.css";
@import "assets/plugins/web-fonts/font-awesome/font-awesome.min.css";
@import "assets/plugins/web-fonts/plugin.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.ng2-tag-input{
    border-bottom: 0 !important;
    padding: 0 !important;
    tag-input-form{width: 100%;}
    input{
        display: block !important;
        width: 100% !important;
        border: 1px solid #e8e8f7 !important;
        border-radius: 5px !important;
        padding: 0.375rem 0.75rem !important;
    }
}


.ng-select.ng-select-multiple{
    &.ng-select-multiple{
        .ng-select-container .ng-value-container .ng-placeholder{padding-bottom: 0;line-height: 1.9;}
        .form-control{height: fit-content; min-height: 38px;}

        .ng-select-container .ng-value-container .ng-value{
            background-color: #5fe07b44;
            color: #14862d;

            .ng-value-icon{
                &:hover{background-color: #2a9e43ab; color: #fffc;}
                &.left{border-color: #2a974266;}
            }
        }
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option{
        &.ng-option-selected,
        &.ng-option-selected.ng-option-marked,
        &.ng-option-marked{
            background-color: #5fe07b44 !important;
            color: #11882b !important;
        }
    }

    &.span-red.ng-select-multiple{
        .ng-select-container .ng-value-container .ng-value{
            background-color: #ff000022;
            color: #b30000;

            .ng-value-icon{
                &:hover{background-color: #cf0000ab; color: #fffc;}
                &.left{border-color: #9e00006e;}
            }
        }

        .ng-dropdown-panel .ng-dropdown-panel-items .ng-option{
            &.ng-option-selected,
            &.ng-option-selected.ng-option-marked,
            &.ng-option-marked{
                background-color: #ff000022 !important;
                color: #b30000 !important;
            }
        }
    }
}

.__fixPicker{
    ngx-daterangepicker-material .md-drppicker{
        flex-direction: column;
        .calendar-time{text-align: left; padding: 0 10px;}
        .buttons{margin-top: -42px;}
    }
}

.mat-paginator{
    background: transparent;
}


.swal2-confirm{
    background-color: var(--success) !important;
}

.__instructions{
    height: fit-content;
    margin: auto;
    text-align: center;
    padding: 50px 20px;
    opacity: 0.7;

    .fa{color: var(--dark);font-size: 6em; opacity: 0.4; margin-bottom: 50px;}
    h2{color: var(--primary); font-weight: 300;}
    p{font-size: 1.2em; margin: 20px 0 0;}
}

.__band{
    .opacity{opacity: 0.3;}
    &::after{
        content: attr(band-label);
        display: block;
        font-weight: 500;
        width: 130%;
        height: auto;
        padding: 8px 0;
        line-height: 1;
        color: white;
        box-shadow: 1px 3px 8px #000a;
        z-index: 2;
        text-align: center;
        background: linear-gradient(45deg, #a83bd6, #2e00d8);
        position: absolute;
        top: 30px;
        left: -52%;
        transform: rotate(-45deg);
        transform-origin: center;
    }
    &.warning::after{background: linear-gradient(45deg, #d6763b, #da0d73);}
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled{color: #4867b5;}